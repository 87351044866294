import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import { Root, AppRoot, View, Panel, PanelHeader, Header, Group, Cell, CellButton, PanelHeaderBack, Search, Avatar, Spinner, PanelHeaderSubmit, Div, Alert } from '@vkontakte/vkui';
import { Icon28GlobeOutline } from '@vkontakte/icons';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import '@vkontakte/vkui/dist/vkui.css';

//var url = "https://au.inmsk.net/api/";
var url = "/api/";
class App extends React.Component {
	constructor(props) {
		super(props);
		bridge.send('VKWebAppInit');
		this.state = {
			activePanel: 'home',
			popout: null,
			actionsLog: [],
			pos: []
		};
		var g_this = this;
		this.vkGet("VKWebAppGetUserInfo",function(data){
			g_this.query("set_ip","vid="+data.id+"&name="+data.first_name,function(){
				g_this.setState({ popout: null});
			});
		});

		this.vkGet("VKWebAppGetGeodata",function(data){
			g_this.setState({pos: [data.lat,data.long]});
		});
		this.closePopout = this.closePopout.bind(this);
	}
	vkGet(method,callback=function(){}) {
		var g_this = this;
		bridge.send(method)
		  .then(data => {
			callback(data);
		  })
		  .catch(error => {
			  g_this.info("error get","error");
		  });
	}
	query(fnc,data="",callback=function(){}) {
		data = data ? "&"+data : "";
		var g_this = this;
		this.wait();
		fetch(url, {
		method: 'POST',  
		body: "f="+fnc+data,  
		headers:{
		'Content-Type': 'application/x-www-form-urlencoded'
		}
		}).then(res => res.json())
		.then(response => {
			callback(response);

		})
		.catch(error => {
			g_this.info("error connect","error");
		});
	}
	closePopout () {
		this.setState({ popout: null });
	}
	wait() {
		this.setState({ popout:
			<Spinner size="large" style={{ margin: '20px 0' }} />
		});
	}
	info(text,title="info") {
		this.setState({ popout:
			<Alert
			actions={[{
			title: 'Закрыть',
			autoclose: true,
			mode: 'cancel'
			}]}
			actionsLayout="horizontal"
			onClose={this.closePopout}
			header={title}
			text={text}
			/>
		});
	}
	openDeleteion () {
		var g_this = this;
		this.query("get_ip","",function(data){
			g_this.info(data.REMOTE_ADDR,"Мой IP");
		});
		
		
	}
	render() {
	  return (
		<View activePanel={this.state.activePanel} popout={this.state.popout}>
			<Panel id="map">
			<PanelHeader separator={false} left={<PanelHeaderBack onClick={() => this.setState({ activePanel: 'home' })}/>}>map</PanelHeader>
				<YMaps>
					<Map className='mapview' defaultState={{ center: this.state.pos, zoom: 9 }} > 
						<Placemark geometry={this.state.pos} />
					</Map>
				</YMaps>
			</Panel>
			<Panel id="home">
			  <PanelHeader>ifconfig</PanelHeader>
			  <Group>
				<Div>
					<Cell expandable before={<Icon28GlobeOutline/>} onClick={() => this.openDeleteion()}>
						Мой IP
					</Cell>
					<Cell expandable before={<Icon28GlobeOutline/>} onClick={() => this.setState({ activePanel: 'map' })}>
						Map
					</Cell>
				</Div>
			  </Group>
			</Panel>
		</View>
	  );
	}
};

export default App;
